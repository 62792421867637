.dlo-wheel-wrapper {
  -webkit-mask-size: 150% 100%;
  mask-size: 150% 100%;
  -webkit-mask-position: center;
  mask-position: center;
}
@media (min-width: 768px) {
  .dlo-wheel-wrapper {
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
}
.dlo-wheel-wrapper .ds-countdown {
  font-size: 0.5em;
}
.dlo-wheel-wrapper .ds-countdown__piece {
  font-size: 0.5em;
}
.dlo-wheel-wrapper .ds-countdown__slot {
  position: unset;
  margin-top: 0.3rem;
  font-size: 1rem;
}
.dlo-wheel-wrapper .multi-client-top-spot__lottie {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}
.dlo-wheel-wrapper .multi-client-top-spot__lottie-player {
  position: absolute;
  top: 0;
  width: 500rem;
  height: 100%;
}
