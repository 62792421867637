.ds-countdown {
  display: flex;
  gap: 0 0.4em;
  perspective: 40em;
  text-align: center;
  line-height: 1;
  --half-height: 0.8em;
  --border-radius: 0.2em;
}
.ds-countdown__piece {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ds-countdown__slot {
  position: absolute;
  top: 5.2em;
  left: -1em;
  right: -1em;
  font-size: 0.7em;
  color: white;
  text-align: center;
  font-weight: 400;
}
.ds-countdown__card {
  display: block;
  position: relative;
  padding-bottom: var(--half-height);
  font-size: 2em;
  line-height: 1.1;
}
.ds-countdown__top,
.ds-countdown__bottom,
.ds-countdown__back:before,
.ds-countdown__back:after {
  display: block;
  height: var(--half-height);
  color: #222;
  background: #ccc;
  padding: 0.25em;
  border-radius: var(--border-radius) var(--border-radius) 0 0;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 1.7em;
  transform: translateZ(0);
}
.ds-countdown__bottom {
  color: #222;
  position: absolute;
  top: 50%;
  left: 0;
  border-top: solid 0.5px transparent;
  background: white;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  pointer-events: none;
  overflow: hidden;
}
.ds-countdown__bottom:after {
  display: block;
  margin-top: -0.8em;
}
.ds-countdown__back:before,
.ds-countdown__bottom:after {
  content: attr(data-value);
}
.ds-countdown__back {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
}
.ds-countdown__back:before {
  position: relative;
  z-index: -1;
  overflow: hidden;
}
.flip .ds-countdown__back:before {
  animation: dsCountdownFlipTop 0.3s cubic-bezier(0.37, 0.01, 0.94, 0.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}
.flip .ds-countdown__back .ds-countdown__bottom {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: dsCountdownFlipBottom 0.6s cubic-bezier(0.15, 0.45, 0.28, 1);
}
@keyframes dsCountdownFlipTop {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }
  0%,
  99% {
    opacity: 0.99;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}
@keyframes dsCountdownFlipBottom {
  0%,
  50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }
  51% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}
