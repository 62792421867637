.dli-wheel-wrapper {
  -webkit-mask-size: 150% 100%;
  mask-size: 150% 100%;
  -webkit-mask-position: center;
  mask-position: center;
}
@media (min-width: 768px) {
  .dli-wheel-wrapper {
    -webkit-mask-size: 100% 100%;
    mask-size: 100% 100%;
  }
}
