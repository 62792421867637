﻿@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Colors/_colors";

.ds-winning-numbers__games-navigation {
  --boxPadding: 3rem;
  --clipPathPosition: 0;
  --boxPosition: 0;
  --eurojackpotImgWidth: 0;
  --lottoImgWidth: 0;
  --vikinglottoImgWidth: 0;
  --kenoImgWidth: 0;
  --allornothingImgWidth: 0;
  --eurojackpotBox: ~"calc(var(--boxPadding) * 2 + var(--eurojackpotImgWidth))";
  --lottoBox: ~"calc(var(--boxPadding) * 2 + var(--lottoImgWidth))";
  --vikinglottoBox: ~"calc(var(--boxPadding) * 2 + var(--vikinglottoImgWidth))";
  --kenoBox: ~"calc(var(--boxPadding) * 2 + var(--kenoImgWidth))";
  --allornothingBox: ~"calc(var(--boxPadding) * 2 + var(--allornothingImgWidth))";
  --boxTransition: 200ms ease-out;

  margin-bottom: 2rem;
}

.ds-winning-numbers__games-selector {
  position: relative;
  width: 100%;
  height: 5.5rem;
  overflow: hidden;
}

.ds-winning-numbers__games-selector-inner {
  position: relative;
  user-select: none;
  height: 10rem; /* higher than parent, to hide the scrollbar */
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  scroll-padding: 0;
  display: grid;
  padding: 0;

  .ds-winning-numbers.initialize & {
    scroll-snap-type: unset;
    scroll-behavior: unset;
  }

  @media @lg {
    justify-content: center;
    scroll-snap-type: unset;
    scroll-padding: unset;
    scroll-behavior: unset;
  }
}

.ds-winning-numbers__games {
  margin: 0;
  padding: 0 50rem;
  display: grid;
  gap: 1rem;
  position: relative;
  height: 4.8rem;
  grid-column: 1 / -1;
  grid-row: 1 / -1;

  &::after {
    content: "";
    position: absolute;
    inset: 0.1rem 50.1rem;
    border: 0.1rem solid @ds-gray-100;
    border-radius: 10rem;

    @media @lg {
      inset: 0.1rem;
    }
  }

  @media @lg {
    padding: 0;
    transition: var(--boxTransition);

    .ds-winning-numbers.initialize & {
      transition: unset;
    }
  }

  &--white {
    pointer-events: none;
    z-index: 2;

    &::after {
      display: none;
    }

    .ds-winning-numbers[data-selected-game="eurojackpot"] & {
      clip-path: circle(~"calc(var(--eurojackpotBox) / 2)" at var(--clipPathPosition) 50%);
    }

    .ds-winning-numbers[data-selected-game="lotto"] & {
      clip-path: circle(~"calc(var(--lottoBox) / 2)" at var(--clipPathPosition) 50%);
    }

    .ds-winning-numbers[data-selected-game="vikinglotto"] & {
      clip-path: circle(~"calc(var(--vikinglottoBox) / 2)" at var(--clipPathPosition) 50%);
    }

    .ds-winning-numbers[data-selected-game="keno"] & {
      clip-path: circle(~"calc(var(--kenoBox) / 2)" at var(--clipPathPosition) 50%);
    }

    .ds-winning-numbers[data-selected-game="allornothing"] & {
      clip-path: circle(~"calc(var(--allornothingBox) / 2)" at var(--clipPathPosition) 50%);
    }
  }

  li {
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.8rem;
    scroll-snap-align: center;
    scroll-snap-stop: always;

    @media @lg {
      scroll-snap-align: unset;
      scroll-snap-stop: unset;
    }
  }
}

.ds-winning-numbers__box {
  pointer-events: none;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  transform: translatex(-50%);
  height: 4.8rem;
  border-radius: 4.8rem;
  background-color: currentcolor;
  padding: 0 var(--boxPadding);
  transition: var(--boxTransition);
  color: transparent;

  .ds-winning-numbers.initialize & {
    transition: none;
  }

  .ds-winning-numbers[data-selected-game="eurojackpot"] & {
    color: black;
    width: var(--eurojackpotBox);
  }

  .ds-winning-numbers[data-selected-game="lotto"] & {
    color: @ds-red;
    width: var(--lottoBox);
  }

  .ds-winning-numbers[data-selected-game="vikinglotto"] & {
    color: @ds-blue;
    width: var(--vikinglottoBox);
  }

  .ds-winning-numbers[data-selected-game="keno"] & {
    color: @ds-light-blue;
    width: var(--kenoBox);
  }

  .ds-winning-numbers[data-selected-game="allornothing"] & {
    color: @ds-dustypurple-900;
    width: var(--allornothingBox);
  }

  @media @lg {
    left: var(--boxPosition);
    transform: none;
  }
}

.ds-winning-numbers__box-arrow {
  position: absolute;
  left: 50%;
  top: 4.7rem;
  width: 0;
  height: 0;
  border-left: 0.7rem solid transparent;
  border-right: 0.7rem solid transparent;
  border-top-width: 0.6rem;
  border-top-style: solid;
  border-top-color: currentcolor;
  transform: translatex(-50%);
  transform-origin: left;
  color: transparent;
  transition: var(--boxTransition);

  .ds-winning-numbers.initialize & {
    transition: none;
  }

  .ds-winning-numbers[data-selected-game="eurojackpot"] & {
    color: black;

    @media @lg {
      left: ~"calc(var(--boxPosition) + calc(var(--eurojackpotBox) / 2))";
    }
  }

  .ds-winning-numbers[data-selected-game="lotto"] & {
    color: @ds-red;

    @media @lg {
      left: ~"calc(var(--boxPosition) + calc(var(--lottoBox) / 2))";
    }
  }

  .ds-winning-numbers[data-selected-game="vikinglotto"] & {
    color: @ds-blue;

    @media @lg {
      left: ~"calc(var(--boxPosition) + calc(var(--vikinglottoBox) / 2))";
    }
  }

  .ds-winning-numbers[data-selected-game="keno"] & {
    color: @ds-light-blue;

    @media @lg {
      left: ~"calc(var(--boxPosition) + calc(var(--kenoBox) / 2))";
    }
  }

  .ds-winning-numbers[data-selected-game="allornothing"] & {
    color: @ds-dustypurple-900;

    @media @lg {
      left: ~"calc(var(--boxPosition) + calc(var(--allornothingBox) / 2))";
    }
  }
}
