﻿@import (reference) "../../AvalonComponents/Styles/Variables/breakpoints";
@import (reference) "../../AvalonComponents/Styles/Colors/_colors";

.ds-winning-numbers {
  width: 100%;
  padding: 0;
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
}

.ds-winning-numbers__title {
  font-family: 'DanskeSpil', sans-serif;
  font-size: 3.6rem;
  line-height: 1.15;
  font-weight: 500;
  letter-spacing: -0.02em;
  text-align: center;
  color: black;
  margin-bottom: 2rem;

  @media @lg {
    font-size: 5.6rem;
    margin-bottom: 2.8rem;
  }
}

.ds-winning-numbers__results {
  margin: 0 auto;
  display: grid;
}

.ds-winning-numbers__result {
  position: relative;
  display: inline-grid;
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  transform: translateY(1rem);
  transition: all 340ms var(--bezierEaseInOutQuad);
  opacity: 0;
  pointer-events: none;
  width: 0;
  overflow: hidden;
  grid-template-areas: "date" "label-numbers" "numbers" "label-bonus" "numbers-bonus" "cta";
  grid-template-rows: 4.8rem 1.8rem 8.8rem 1.8rem 8.8rem auto;

  &--keno,
  &--allornothing {
    grid-template-areas: "date" "numbers" "cta";
    grid-template-rows: 4.8rem 21.2rem auto;
  }

  .ds-winning-numbers[data-selected-game="eurojackpot"] &--eurojackpot,
  .ds-winning-numbers[data-selected-game="lotto"] &--lotto,
  .ds-winning-numbers[data-selected-game="vikinglotto"] &--vikinglotto,
  .ds-winning-numbers[data-selected-game="keno"] &--keno,
  .ds-winning-numbers[data-selected-game="allornothing"] &--allornothing {
    transform: translateY(0);
    opacity: 1;
    pointer-events: unset;
    width: unset;
    overflow: unset;
  }

  @media @lg {
    width: 75rem;
    justify-content: space-between;
    grid-template-areas: "date date date" "label-numbers label-bonus label-bonus" "numbers . numbers-bonus" "cta cta cta";
    grid-template-rows: 4.6rem 2.8rem 14.4rem auto;
    margin-bottom: 5rem; // make room for the note
    &--keno,
    &--allornothing {
      grid-template-areas: "date" "numbers" "cta";
      grid-template-rows: 4.6rem 17.2rem auto;
      justify-content: center;
    }
  }
}

.ds-winning-numbers__date {
  grid-area: date;
  font-size: 2rem;
  line-height: 1;
  font-weight: 800;
  letter-spacing: -0.02em;
  text-align: center;
  color: black;
}

.ds-winning-numbers__numbers-label {
  grid-area: label-numbers;
  font-size: 1.2rem;
  line-height: 1;
  font-weight: bold;
  text-transform: uppercase;
  color: @ds-gray-450;

  &--bonus {
    grid-area: label-bonus;

    @media @lg {
      justify-self: end;
    }
  }

  @media @lg {
    font-size: 1.6rem;
  }

  .ds-winning-numbers__result--keno &,
  .ds-winning-numbers__result--allornothing & {
    display: none;
  }
}

.ds-winning-numbers__numbers {
  grid-area: numbers;
  padding: 0.8rem;
  background: @ds-gray-75;
  border-radius: 10rem;
  display: inline-flex;
  gap: 0.4rem;
  margin: 0 auto 0 0;
  height: fit-content;
  overflow: hidden;

  &--bonus {
    grid-area: numbers-bonus;

    @media @lg {
      margin-left: auto;
    }
  }

  @media @lg {
    padding: 1.4rem;
    gap: 1rem;
  }

  .ds-winning-numbers__result--keno & {
    display: inline-grid;
    grid-template-columns: repeat(5, 4rem);
    padding: 0;
    gap: 0.8rem;
    margin-left: auto;
    background: none;
    border-radius: 0;

    @media @lg {
      grid-template-columns: repeat(10, 6.4rem);
      gap: 1rem;
    }
  }

  .ds-winning-numbers__result--allornothing & {
    display: inline-grid;
    grid-template-columns: repeat(3, 4rem);
    gap: 0.8rem;
    margin-left: auto;
    background: @ds-dustypurple-900;
    border-radius: 0.8rem;
    padding: 1rem;

    @media @lg {
      grid-template-columns: repeat(6, 6.4rem);
      gap: 1rem;
    }
  }
}

.ds-winning-numbers__ball {
  position: relative;
  height: 4rem;
  width: 4rem;
  border-radius: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.3rem;
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.05rem;
  color: #e2c000;
  background-size: cover;
  overflow: hidden;
  right: -33rem;

  .ds-winning-numbers.animate & {
    animation-name: winning-numbers-drawn-balls-bounce, winning-numbers-drawn-balls-direction;
    animation-duration: 1000ms, 600ms;
    animation-timing-function: ease, ease-out;
    animation-fill-mode: forwards;
  }

  .ds-winning-numbers.no-animate & {
    animation: none;
  }

  @media @lg {
    height: 6.4rem;
    width: 6.4rem;
    font-size: 2rem;
    right: -75rem;
  }

  .ds-winning-numbers__result--eurojackpot & {
    background-image: url(/Components/DanskeSpil/Domain/Feature.Components.Dlo/Graphics/WinningNumbers/ball-eurojackpot.svg);
  }

  .ds-winning-numbers__result--lotto & {
    background-image: url(/Components/DanskeSpil/Domain/Feature.Components.Dlo/Graphics/WinningNumbers/ball-lotto.svg);
  }

  .ds-winning-numbers__result--vikinglotto & {
    background-image: url(/Components/DanskeSpil/Domain/Feature.Components.Dlo/Graphics/WinningNumbers/ball-vikinglotto.svg);
  }

  .ds-winning-numbers__result--keno &,
  .ds-winning-numbers__result--allornothing & {
    background: @ds-light-blue;
    color: white;
    border-radius: 0.4rem;
    flex: 0 0 4rem;
    font-style: italic;
    font-size: 2.4rem;
    font-weight: bold;
  }

  .ds-winning-numbers__result--allornothing & {
    background-color: @ds-neongreen;
    background-image: none;
    font-style: normal;
    font-weight: normal;
    color: black;

    &::after {
      content: "";
      position: absolute;
      inset: -1rem;
      background-image: url(/Components/DanskeSpil/Domain/Feature.Components.Dlo/Graphics/WinningNumbers/Horseshoe.svg);
      transform: rotate(15deg);
      background-repeat: no-repeat;
      background-size: 5.4rem;
      opacity: 10%;
      background-position: 1.3rem -0.2rem;

      @media @lg {
        background-size: 5.6rem;
        background-position: 2.6rem 1rem;
      }
    }

    &::before {
      @media @lg {
        content: "";
        position: absolute;
        inset: -1rem;
        background-image: url(/Components/DanskeSpil/Domain/Feature.Components.Dlo/Graphics/WinningNumbers/Clover.svg);
        transform: rotate(15deg);
        background-repeat: no-repeat;
        background-size: 2.2rem;
        opacity: 10%;
        background-position: 0.3rem 1rem;
      }
    }
  }


  &:nth-child(1),
  &:nth-child(8),
  &:nth-child(15) {
    animation-delay: 0ms;
    z-index: 10;
  }

  &:nth-child(2),
  &:nth-child(9),
  &:nth-child(16) {
    animation-delay: 100ms;
    z-index: 9;
  }

  &:nth-child(3),
  &:nth-child(10),
  &:nth-child(17) {
    animation-delay: 100ms;
    z-index: 8;
  }

  &:nth-child(4),
  &:nth-child(11),
  &:nth-child(18) {
    animation-delay: 70ms;
    z-index: 7;
  }

  &:nth-child(5),
  &:nth-child(12),
  &:nth-child(19) {
    animation-delay: 150ms;
    z-index: 6;
  }

  &:nth-child(6),
  &:nth-child(13),
  &:nth-child(20) {
    animation-delay: 160ms;
    z-index: 5;
  }

  &:nth-child(7),
  &:nth-child(14) {
    animation-delay: 130ms;
    z-index: 4;
  }


}

.ds-winning-numbers__cta {
  grid-area: cta;
  height: 5.6rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.4);
  border-radius: 5.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: 800;
  letter-spacing: 0.1rem;
  color: black;
  padding: 0 4rem;
  margin: 0.6rem auto 0;
}

.ds-winning-numbers__note {
  pointer-events: none;
  font-family: "Shadows Into Light", cursive;
  font-size: 1.7rem;
  line-height: 1.1;
  letter-spacing: 0.06em;
  color: @ds-gray-450;
  position: absolute;
  right: 0;
  top: 16rem;
  transform: rotate(-25deg);
  text-align: center;
  max-width: 14rem;

  .ds-winning-numbers__result--eurojackpot & {
    right: -3rem;
  }

  .ds-winning-numbers[data-selected-game="keno"] &,
  .ds-winning-numbers[data-selected-game="allornothing"] & {
    display: none;
  }

  @media @lg {
    font-size: 3.4rem;
    max-width: 28rem;
    transform: rotate(25deg);
    right: unset;
    top: 21rem;
    left: -11rem;
  }

  &::before {
    content: "";
    position: absolute;
    z-index: 1;
    top: -2.8rem;
    left: 50%;
    transform: translateX(-50%) rotate(25deg);
    width: 2.1rem;
    height: 2.1rem;
    background-color: white;
    background-image: url(/Components/DanskeSpil/Domain/Feature.Components.Dlo/Graphics/WinningNumbers/arrow.svg);
    background-size: cover;

    @media @lg {
      top: -5rem;
      transform: scaleX(-1) rotate(5deg);
      width: 4rem;
      height: 4rem;
    }
  }
}

@keyframes winning-numbers-drawn-balls-bounce {
  0% {
    opacity: 0;
    transform: translateX(30rem) rotate(70deg);
  }
  60% {
    opacity: 1;
    transform: translateX(-0.5rem) rotate(0deg);
  }
  80% {
    transform: translateX(0.2rem) rotate(-1deg);
  }
  90% {
    transform: translateX(0.1rem) rotate(0deg);
  }
  100% {
    transform: translateX(0) rotate(0deg);
  }
}

@keyframes winning-numbers-drawn-balls-direction {
  to {
    right: 0;
  }
}